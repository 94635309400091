.main {
    margin: 20px 20px 0px 30px;
    background-color: #fff;
    border-radius: 5px;
    padding: 16px;
}

.main-1 {
    margin: 20px 20px 0px 30px;
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 5px;
}


.dashboard-content-text h2 {
    font-family: "Poppins", sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
    color: #4F4F4F;
    margin-bottom: 20px;
}

.dashboard-content-text h5 {
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    color: #4F4F4F;
}

.dashboard-count {
    margin-top: 50px;
}

.dashboard-box {
    border: 1px solid #092C4C26;
    box-shadow: 0px 2px 4px 0px #092C4C40;
    background: #FFFFFF;
    border-radius: 5px;
    border-radius: 8px;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer;
}

.dashboard-box-schools{
    border: 1px solid #092C4C26;
    box-shadow: 0px 2px 4px 0px #092C4C40;
    background: #FFFFFF;
    border-radius: 5px;
    border-radius: 8px;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.dashboard-box-schools img{
    margin-bottom: 20px;
}

.dashboard-box img {
    margin-bottom: 20px;
}

.dashboard-box-schools p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #092C4C;
    margin-bottom: 10px;
}

.dashboard-box p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #092C4C;
    margin-bottom: 10px;
}

.dashboard-box-schools h6 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: #092C4C;
}

.dashboard-box h6 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: #092C4C;
}

.admin-section {
    margin-top: 50px;
}

.admin-conntent ul {
    display: flex;
    padding: 0px;
    margin: 0px;
    list-style: none;
    align-items: flex-start;
}

.admin-conntent ul li:last-child {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.admin-conntent ul li:last-child h6 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    color: #4F4F4F;
    margin-bottom: 7px;
}

.admin-conntent ul li:last-child p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #4F4F4F;
    width: 500px;
}

.admin-schools {
    margin-top: 30px;
}

/* admin's */
.employees-main {
    margin-top: 20px;
    background-color: #FFFFFF;
    border: 0.75px solid #509CDB33;
    padding: 10px;
    border-radius: 5px;
}

.inventory-main {
    padding: 25px !important;
}

.inventory-container{
    height: 400px;
    overflow-y: scroll;
}

.employees-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    padding: 12px;
    border-radius: 12px 12px 0px 0px !important;
    overflow: hidden;
}

.employees-content h5 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;
}

.employees-content h5 span {
    font-weight: 500;
}

.transaction-menu {
    padding: 15px 0px !important;
}

.tickect-content {
    display: flex !important;
    justify-content: flex-end !important;
}

.search-options {
    display: flex;
    align-items: center;
}

.search-options .MuiFormControl-root .MuiInputBase-formControl {
    background-color: #FFFFFF;
    padding: 5px 9px !important;
    border-radius: 6px !important;
}

.search-options .MuiFormControl-root .MuiInputBase-formControl .MuiInputAdornment-root .MuiSvgIcon-root {
    font-size: 18px !important;
    color: #383838 !important;
}

.search-options .MuiOutlinedInput-input {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    padding: 0px 0px 0px 7px !important;
    color: #383838;
}

.search-options .MuiOutlinedInput-notchedOutline {
    border: 0.6px solid #C6C6C6 !important;
    border-radius: 6px !important;
}

.employee-checkin-date-1 .MuiFormControl-root .MuiInputBase-formControl {
    padding: 10px !important;
    /* background-color: #E3F2FF !important; */
}

.employee-checkin-date-1 .MuiFormControl-root {
    width: 100% !important;
}

.employee-checkin-date-1 .MuiOutlinedInput-input {
    padding: 5px 8px !important;
}

.employee-checkin-date-1 .MuiOutlinedInput-root {
    height: 40px !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #383838 !important;
}

.employee-checkin-date-1 .MuiInputLabel-root {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    color: #383838 !important;
    top: 5px !important;
}

.pagination-options {
    display: flex;
    align-items: center;
}

.employees-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employees-text h5 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;
}

.employees-text h5 span {
    font-weight: 500;
}

.inventory-backtn h6 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.78px;
    color: #202224;
    margin-top: 10px;
}

.inventory-backtn h6 span {
    font-weight: 500;
}

.inventory-backtn .MuiButton-root {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: #509CDB;
    border: none !important;
    padding: 8px 14px !important;
    border-radius: 6px;
    display: flex !important;
    align-items: center !important;
}

.inventory-backtn .MuiButton-root .MuiSvgIcon-root {
    font-size: 23px;
    margin-right: 6px;
}

.pagination-options .MuiTablePagination-actions {
    margin-left: 10px !important;
    border-right: 1px solid #D9D9DC !important;
}

.table-pagination .MuiTablePagination-actions {
    border: 0px !important;
}

.pagination-options .MuiTablePagination-toolbar {
    padding: 0px !important;
    min-height: 0px !important;
}

.pagination-options .MuiTablePagination-displayedRows {
    margin: 0px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #383838;
    letter-spacing: 2px;
}

.pagination-options .MuiTablePagination-displayedRows span {
    color: #38383880;
    margin: 0px 1px;

}

.pagination-options .MuiTablePagination-actions .MuiIconButton-root {
    padding: 4px 4px !important;
}

.pagination-options .MuiTablePagination-actions .MuiIconButton-root:hover {
    background-color: unset !important;
}

.filter {
    margin-left: 10px;
}

.filter .MuiIconButton-root .MuiSvgIcon-root {
    color: #8ECAFE;
}

.filter-options .MuiMenu-paper {
    width: 500px;
    max-width: 500px;
    border: 0.8px solid #509CDB !important;
    background: #FFFFFF !important;
    border-radius: 12px !important;
    right: 0px !important;
}

.filter-options .filter-menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #509CDB;
    padding: 15px 16px !important;
}

.filter-options .filter-menu h6 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    color: #383838;
}

.filter-options .filter-menu .MuiSvgIcon-root {
    color: #ff0000;
    cursor: pointer;
    font-size: 20px;
}

/* table */
.employee-table .MuiTableContainer-root {
    background: #FFFFFF !important;
    border-radius: 0px 0px 6px 6px !important;
}

.employee-table .MuiTableHead-root {
    background-color: #E3F2FF;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
    padding: 14px 15px;
    border: unset !important;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .employee-index {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #000000;
    width: 40px;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .employee-name {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #000000;
    white-space: nowrap;
}

.employee-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root .employee-name-1 {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #000000;
    white-space: nowrap;
    text-align: center;
}

.employee-table .MuiTableBody-root .MuiTableRow-root {
    border: 0px !important;
}

.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    padding: 12px 15px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: #4F4F4F;
}

.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root .view img {
    cursor: pointer;
}

.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #4F4F4F;
}
.employee-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root strong{
    color: #509CDB;
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 21px;
    margin-left: 4px;
    /* color: #4F4F4F; */
}
.employees-text .MuiButton-root {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: #509CDB;
    border: none !important;
    padding: 10px 12px;
    border-radius: 6px;
}

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px
    }

    .loading .MuiCircularProgress-svg {
        color: #509CDB !important;
    }

    .loading .MuiCircularProgress-root {
        width: 50px !important;
        height: 50px !important;
    }

/* add-admin */
.admin-admins .MuiDialog-paper {
    box-shadow: 0px 2px 4px 0px #092C4C40 !important;
    max-width: 550px !important;
    width: 600px !important;
    border-radius: 12px !important;
}

.admin-admins .MuiDialogTitle-root {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #383838;
    line-height: 19px;
}

.admin-admins .MuiDialogContent-dividers .MuiTypography-root {
    color: #383838;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
}

.admin-admins .MuiDialog-paper .MuiIconButton-sizeMedium {
    color: #ff0000 !important;
}

.admin-admins .MuiDialogActions-root .cancel-btn {
    color: #ff0000 !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.36px;
    text-transform: capitalize;
}

.admin-admins .MuiDialogActions-root .add-btn {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.36px;
    background: #509CDB;
    color: #FCFAFA;
    padding: 10px 20px;
    text-transform: capitalize;
}

.add-admin h5 {
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    color: #4F4F4F;
    margin-bottom: 25px;
}

.admin-textfiled .MuiOutlinedInput-input {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    padding: 14px !important;
    background-color: #FFFFFF;

}

.admin-textfiled .MuiInputLabel-root {
    top: -2px !important;
    color: #8A8A8A;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}

.admin-textfiled .MuiOutlinedInput-notchedOutline {
    border: 1px solid #A7A7A7 !important;
    border-radius: 5px !important;
}

.admin-textfiled .MuiInputLabel-root.Mui-focused {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22.4px;
    color: #8A8A8A;
}

.admin-textfiled .MuiFormLabel-asterisk {
    color: #ff0000 !important;
}

.inventory-count {
    border: 1px solid #A7A7A7 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    border-radius: 5px !important;
    padding: 5px 19px;
}

.admin-textfiled .MuiFormControl-root {
    width: 100%;
}

.inventory-count .MuiOutlinedInput-root {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 0px !important;
    background-color: #FFFFFF;
}

.inventory-count .MuiInputBase-input {
    width: 50px;
    padding: 9px 7px !important;
    text-align: center;
}

.inventory-count .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.inventory-count p {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-left: -50px !important;
    width: 80px;
}

.inventory-count .count-minus,
.inventory-count .count-plus {
    color: #509CDB;
    min-width: 0px !important;
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.inventory-count .count-minus:hover,
.inventory-count .count-plus:hover {
    background-color: unset !important;
}

.add-inventory-btn {
    text-align: right !important;
}

.add-inventory-btn .MuiButton-root {
    background: #509CDB !important;
    color: #fff !important;
    border-radius: 5px !important;
    padding: 12px 16px !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
}

/* transaction  */
.filter-options .filter-status {
    padding: 30px 16px 25px 16px;
    border-bottom: 1px solid #509CDB;
}

.employee-checkin-date {
    display: flex;
    justify-content: space-between !important;
}

.employee-checkin-date .MuiFormControl-root:first-child {
    margin-right: 20px;
}

.employee-checkin-date .MuiFormControl-root {
    width: 100% !important;
}

.employee-checkin-date .MuiOutlinedInput-input {
    padding: 5px 8px !important;
}

.employee-checkin-date .MuiOutlinedInput-root {
    height: 40px !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: #383838 !important;
}

.employee-checkin-date .MuiInputLabel-root {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    color: #383838 !important;
    top: 3px !important;
}

.apply_fliters .MuiButton-root {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #FFFFFF;
    text-transform: capitalize;
    background: #509CDB !important;
    border: 1px solid #509CDB;
    padding: 10px 18px 10px 18px !important;
    width: 100%;
    max-width: 80%;
}

.apply_fliters {
    display: flex;
    justify-content: center;
    padding: 18px 22px 18px 22px !important;
}

.employee-table .MuiAccordion-root:not(:last-child) {
    margin-bottom: 20px;
}

.tranaction-accordion .MuiAccordion-root {
    margin-top: 20px;
}

.employee-table .MuiAccordionSummary-content {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    color: #202224;
}

.employee-table .MuiAccordionSummary-content span {
    font-weight: 600;
    margin-left: 5px;
}

.employee-table .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root {
    color: #202224;
    ;
}

.transaction-main {
    height: 525px;
    overflow-y: scroll;
}

.transaction-main::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
}

.transaction-main::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
}

.student-textfiled .MuiInputLabel-root {
    /* top: 3px !important; */
    color: #8A8A8A;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}

.student-textfiled .MuiFormControl-root {
    width: 100%;
}

.student-textfiled .MuiOutlinedInput-input {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    padding: 14px !important;
    background-color: #FFFFFF;
}

.student-textfiled .MuiInputAdornment-root .MuiIconButton-sizeMedium,
.student-textfiled .MuiInputAdornment-root .MuiSvgIcon-root {
    color: #000 !important;
}

.student-textfiled .MuiInputLabel-root.Mui-focused {
    color: #000 !important;
}

.admin-textfiled input[type=file]::file-selector-button {
    color: #FFFFFF;
    text-transform: capitalize;
    background: #509CDB !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 23px;
    border-radius: 4px;
    border: none !important;
    margin-right: 15px;
}
.admin-textfiled-1  .MuiOutlinedInput-input{
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    padding: 15px !important;
    background-color: #FFFFFF;
}
.admin-textfiled-1 .MuiInputLabel-root{
    color: #8A8A8A;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
}
.admin-textfiled-1 .MuiButtonBase-root {
    color: rgba(0, 0, 0, 0.54) !important;
}
.admin-textfiled-1 .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54) !important;
}
.admin-textfiled-1  .MuiAutocomplete-root .MuiAutocomplete-inputRoot{
    padding: 0px !important;
}

@media (min-width:768px) and (max-width:991px){
    .inventory-count p{
        margin-left: 0px !important;
    }
}
@media (min-width:992px) and (max-width:1290px){
    .inventory-count p{
        margin-left: 0px !important;
    }
}
.MuiFormControl-root .MuiInputLabel-formControl.Mui-focused {
    color: #4F4F4F !important;
}
.MuiFormControl-root .MuiInputBase-colorPrimary.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2D88D4 !important;
}

.profile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    border-radius: 4px;
    width: 98%;
}

.profile-wrapper {
    border-radius: 4px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    padding: 20px;
    width: 500px;
    background: #fff;
}

.profile_card {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.profile-text h4 {
    display: flex;
    justify-content: center;
    margin-bottom: 15px !important;
    font-size: 20px !important;
    font-family: "Poppins", sans-serif !important;
}

.profile-name .MuiFormControl-root {
    width: 400px !important
}

.change-btn {
    background-color: #509CDB !important;
    margin-top: 15px !important;
}

.profile-back-btn {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #509CDB;
    border: #509CDB;
    padding: 10px 15px;
    font-size: 13px;
    font-family: "Poppins",sans-serif;
    width: 100px;
    color: white;
    border-radius: 4px;
  }
  
  .profile-back-btn-main{
    display: flex;
    justify-content: end;
    margin-right: 20px;
  }

  .menu-container {
    margin-top: 50px;
  }

  .link{
    text-decoration: none !important;
    color: #383838 !important;
  }

  .logout-btn{
    font-family: "Poppins",sans-serif !important;
    color: crimson !important;
  }

  .MuiIconButton-root{
    margin-right: 20px !important;
  }