.login {
    background-image: url('../../assets/login-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 728px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-main {
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 40px 50px;
    width: 450px;
}

.login-content h4 {
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 31px;
    text-align: center;
    color: #000;
    margin-bottom: 10px;
}

.login-content p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    color: #667085;
}
.login-content .MuiFormHelperText-root.Mui-error{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: left !important;
}
.login-content h5 {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #000;
    margin-bottom: 10px;
}
.login-logo .MuiButton-root {
    background: #2D88D4;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    color: #FFFFFF;
    width: 100%;
    text-transform: capitalize;
}

.login-logo .MuiButton-root:hover {
    background: #2D88D4;
    color: #FFFFFF;
}

.login-content .MuiOutlinedInput-input {
    color: #4F4F4F;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 14px !important;
    background-color: #FFFFFF;
}

.login-content .MuiInputLabel-root {
    top: -3px !important;
}

.login-content .MuiOutlinedInput-notchedOutline {
    border: 1px solid #A7A7A7 !important;
    border-radius: 5px !important;
}

.login-content .MuiInputLabel-root.Mui-focused {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22.4px;
    color: #4F4F4F;
}

.login-content .MuiFormLabel-asterisk {
    color: #ff0000 !important;
}
.login-content .MuiInputLabel-root.Mui-focused{
    left: -1px !important;
}

.forgot-text {
    width: 100%;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    color: #2D88D4 !important;
    text-transform: capitalize !important;
   text-decoration: none !important;
}
.forgot-text-1 {
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    background-color: #2D88D4 !important;
    text-transform: capitalize !important;
    text-decoration: none !important;
    color: #FFFFFF !important;
    padding: 10px 0px !important;
    border-radius: 4px;
}
.forgot-text-1 .MuiSvgIcon-root{
    margin-left: 8px;
    font-size: 20px;
}
.send-otp-number input {
    width: 44px !important;
    height: 60px;
    border-radius: 15px;
    border: 0.6px solid rgba(0, 0, 0, 0.30);
    background: #FFF;
    position: relative;
    color: #2c0202;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-top: 05px;
}
 
.send-otp-number inpuT:focus {
    outline: unset;
}
 
.send-otp-number input:not(:first-child) {
    margin-left: 10px;
}
.send-otp-number span {
    font-size: 0;
}
.send-otp-number label{
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    text-align: center;
    margin-bottom: 40px;
}
/* .login-content .MuiInputLabel-root.Mui-focused .MuiFormLabel-asterisk {
    color: #ff0000 !important;
} */