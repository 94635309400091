.topbar{
    background-color: #E9EBF5;
    padding: 14px 24px;
}
.topbar-main{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.topbar-main .MuiButton-root{
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.36px;
    background: #509CDB;
    color: #FCFAFA;
    padding: 10px 14px;
    text-transform: capitalize;
}