
.sidebar-content {
    background: #152259;
    box-shadow: 0px 4px 34px 0px #092C4C0A;
    height: 679px;
    padding: 25px 20px;
}

.sidebar-content .sidebar-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.sidebar-content .sidebar-logo img {
    width: 70px;
}

.sidebar-content .sidebar-logo p {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.36px;
    margin-top: 18px;
    color: #FFFFFF;
}

.toggle-button {
    position: relative;
}

.toggle-button .MuiIconButton-root {
    color: #FFFFFF !important;
    position: absolute;
    top: 15px;
    right: -33px;
    padding: 5px !important;
    background-color: #509CDB !important;
}

.toggle-button .MuiIconButton-root .MuiSvgIcon-root {
    font-size: 18px;
}

.sidebar-content ul {
    padding: 0px;
    margin: 70px 0px 0px 0px;
}

.sidebar-content ul a {
    text-decoration: none;
    color: #fff !important;
}

.sidebar-border {
    margin-top: 20px;
    border-bottom: 0.9px solid #E8E6E3;
}

.sidebar-content ul a li {
    padding: 14px 12px 14px 12px !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.sidebar-content ul li img {
    margin-right: 15px;
    width: 19px !important;
}

.sidebar-content ul .active {
    background: #509CDB !important;
    color: #fff !important;
    border-radius: 5px !important;
}

.sidebar-content ul .active img {
    filter: brightness(0) invert(1);
}

.sidebar-content ul .active a {
    color: #fff !important;
}

.collapsed .sidebar-logo img {
    width: 45px;
}

.collapsed .sidebar-content ul {
    margin: 70px 0px 0px 0px;

}

.collapsed .sidebar-content ul a li {
    padding: 10px 15px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

}

.collapsed .sidebar-content ul .active {
    background: #509CDB !important;
}

.collapsed .sidebar-content ul li img {
    margin: 0px !important;
}

.sidebar-content ul a .tooltip {
    background-color: #1976d2 !important;
    color: white !important;
    font-size: 14px !important;
    padding: 8px 12px !important;
    border-radius: 4px !important;
}

@media (min-width:768px) and (max-width:900px) {
    .toggle-button {
        display: none !important;
    }
    .sidebar-content ul li img{
        margin-right: 0px !important;
    }
    .sidebar-content ul li span{
        display: none !important;
    }
    .sidebar-content .sidebar-logo img{
        width: 50px !important;
    }
    .sidebar-content .sidebar-logo p{
        display: none !important;
    }
    .sidebar-content ul a li {
        padding: 10px  16px !important;
        cursor: pointer;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .sidebar-content {
        padding: 25px 25px !important;
    }
}
.sidebar {
    /* position: fixed;
    top: 0;
    left: 0;
    height: 679px;
    overflow-y: auto;  */
    /* transition: width 0.3s ease !important;  */
    /* z-index: 1000;  */
}
/* .sidebar::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
} */
.content-2 {
    /* margin-left: 250px !important; 
    padding: 20px !important;  */
    /* transition: margin-left 0.3s ease !important; */
    /* transition:  width 0.3s ease !important; */
  }
/*   
  .sidebar.collapsed + .content-2 {
    margin-left: 60px !important;
  } */